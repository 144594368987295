<template>
  <div
    :class="[
      'infinity-sign-banner overflow-hidden p-0',
      { container: !fluid },
      { 'container-fluid': fluid },
    ]"
  >
    <div
      :style="{
        backgroundColor,
        color: textColor,
      }"
      class="infinity-sign-banner__row position-relative overflow-hidden"
    >
      <slot></slot>

      <InfinityIcon
        class="infinity-icon position-absolute"
        :style="{
          fill: iconColor,
          opacity: iconOpacity,
        }"
      />
    </div>
  </div>
</template>

<script>
import InfinityIcon from '~/assets/icons/infinity.svg'

export default {
  components: {
    InfinityIcon,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '#E7E9EF',
    },
    textColor: {
      type: String,
      default: '#14181A',
    },
    iconColor: {
      type: String,
      default: '#2D3D72',
    },
    iconOpacity: {
      type: Number,
      default: 0.2,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.infinity-sign-banner__row {
  padding: 104px 0;
  z-index: 1;
}

.infinity-icon {
  z-index: -1;
  width: 495px;
  height: 302px;
  bottom: -108px;
  right: -104px;
}

:slotted(h1),
:slotted(h2),
:slotted(h3),
:slotted(h4) {
  color: inherit;
}

@include media-breakpoint-down(sm) {
  .infinity-sign-banner__row {
    padding: 64px 0;
    text-align: center;
  }

  .infinity-icon {
    width: 933px;
    height: 570px;
    right: -48px;
    left: unset;
  }
}
</style>
